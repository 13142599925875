var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "轨迹详情", size: 520 },
      on: { close: _vm.handleClose },
    },
    [
      _c("div", { staticClass: "log-body" }, [
        _c("div", { staticClass: "track-num" }, [
          _vm._v(" 追踪单号："),
          _c("span", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.orderData.trackingNumber)),
          ]),
        ]),
        _c("div", { staticClass: "track-other" }, [
          _c("div", { staticClass: "country" }, [
            _c("span", [_vm._v(_vm._s(_vm.orderData.originCountry))]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/order/guide.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.orderData.destinationCountry))]),
          ]),
          _c(
            "div",
            { staticClass: "navication" },
            [
              _vm.orderData.navication <= 2
                ? [
                    _c("div", { staticClass: "nav-step-text" }, [
                      _c("span", { staticStyle: { "margin-right": "40px" } }, [
                        _vm._v("已创建"),
                      ]),
                      _c("span", { staticStyle: { "margin-right": "40px" } }, [
                        _vm._v("运输中"),
                      ]),
                      _c("span", [_vm._v("已签收")]),
                    ]),
                    _c("div", { staticClass: "nav-step-jd" }, [
                      _c("div", { staticClass: "nav-setp-dot nav-dot-blue" }),
                      _c("div", {
                        class: [
                          "nav-setp-line",
                          _vm.orderData.navication > 0 ? "nav-line-blue" : "",
                        ],
                      }),
                      _c("div", {
                        class: [
                          "nav-setp-dot",
                          _vm.orderData.navication > 0 ? "nav-dot-blue" : "",
                        ],
                      }),
                      _c("div", {
                        class: [
                          "nav-setp-line",
                          _vm.orderData.navication > 1 ? "nav-line-blue" : "",
                        ],
                      }),
                      _vm.orderData.navication !== 2
                        ? _c("div", { staticClass: "nav-setp-dot" })
                        : _vm._e(),
                      _vm.orderData.navication === 2
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/order/over.png"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                : _vm._e(),
              _vm.orderData.navication >= 3
                ? [
                    _c("div", { staticClass: "nav-step-text" }, [
                      _c("span", { staticStyle: { "margin-right": "40px" } }, [
                        _vm._v("已创建"),
                      ]),
                      _vm.orderData.navication === 3
                        ? _c("span", [_vm._v("取消")])
                        : _vm._e(),
                      _vm.orderData.navication === 4
                        ? _c("span", [_vm._v("退回")])
                        : _vm._e(),
                      _vm.orderData.navication === 5
                        ? _c("span", [_vm._v("其他")])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "nav-step-jd" }, [
                      _c("div", { staticClass: "nav-setp-dot nav-dot-blue" }),
                      _c("div", {
                        class: [
                          "nav-setp-line",
                          _vm.orderData.navication > 0 ? "nav-line-blue" : "",
                        ],
                      }),
                      _c("div", {
                        class: [
                          "nav-setp-dot",
                          _vm.orderData.navication > 0 ? "nav-dot-red" : "",
                        ],
                      }),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c(
          "div",
          { staticClass: "track-step" },
          _vm._l(_vm.orderData.trackInfo, function (item, index) {
            return _c("div", { key: index, staticClass: "step-item" }, [
              _c("div", { class: ["step-title", index === 0 ? "blue" : ""] }, [
                _c("div", { staticClass: "dot" }, [
                  _c("div", {
                    class: ["step-dot", index === 0 ? "blue-dot" : ""],
                  }),
                ]),
                _c("div", { staticClass: "step-time" }, [
                  _vm._v(_vm._s(item.time) + "  " + _vm._s(item.location)),
                ]),
              ]),
              _c("div", { class: ["step-info", index === 0 ? "blue" : ""] }, [
                _vm._v(" " + _vm._s(item.description) + " "),
              ]),
              index !== _vm.orderData.trackInfo.length - 1
                ? _c("div", {
                    class: ["step-line", , index === 0 ? "blue-line" : ""],
                  })
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }