<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="search">
        <el-popover
          ref="popover"
          class="searchPop"
          placement="top"
          trigger="click"
          width="474"
        >
          <div class="order-content">
            <base-muti-input
              ref="baseMutiInput"
              v-model="searchList"
            ></base-muti-input>
          </div>
          <div class="pop-btn">
            <button class="pop-qx" @click="handleCancel">取消</button>
            <button class="pop-qd" @click="handleConfirm">确定</button>
          </div>
        </el-popover>
        <el-select
          v-model="orderList"
          :multiple="true"
          filterable
          allow-create
          style="width: 500px"
          placeholder="请输入客户单号、追踪单号、提单号"
          @focus="handleFocus"
          @remove-tag="handleRemoveTag"
        >
        </el-select>
        <el-button
          style="margin-left: 10px; height: 40px;"
          type="primary"
          icon="el-icon-search"
          @click="searchRefNo"
        >查询</el-button
        >
      </div>
      <div class="filter">
        <BaseFilter
          :isActive="isActive"
          :width="466"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <el-form ref="form" :model="filterList" label-width="70px">
            <el-form-item label="制单日期">
              <el-date-picker
                v-model="filterList.upDate"
                style="width: 320px;"
                type="daterange"
                range-separator="至"
                align="center"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="出仓日期">
              <el-date-picker
                v-model="filterList.outDate"
                style="width: 320px;"
                type="daterange"
                range-separator="至"
                align="center"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客户">
              <el-select
                v-model="filterList.companyIds"
                :multiple="true"
                filterable
                allow-create
                style="width: 320px;"
                placeholder="请选择客户"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="轨迹状态">
              <el-select
                v-model="filterList.tdStatus"
                :multiple="true"
                filterable
                allow-create
                style="width: 320px;"
                placeholder="请选择客户"
              >
                <el-option label="已创建" value="0"></el-option>
                <el-option label="运输中" value="1"></el-option>
                <el-option label="签收" value="2"></el-option>
                <el-option label="取消" value="3"></el-option>
                <el-option label="退回" value="4"></el-option>
                <el-option label="其他" value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :tableData="tableData"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="tdStatusDescription" slot-scope="row">
          <div class="track">
            <div class="track-title">{{ row.scope.tdStatusDescription }}</div>
            <div class="track-info" style="margin-top:5px;">
              {{ row.scope.tdStatusTime }} {{ row.scope.tdStatusLocation }}
            </div>
          </div>
        </template>
        <template slot="navication" slot-scope="row">
          <div
            v-if="row.scope.navication === 0"
            style="background: rgba(255, 116, 68, 0.1); color: #ff7444"
            class="status"
          >
            已创建
          </div>
          <div
            v-if="row.scope.navication === 1"
            style="background: rgba(53, 186, 131, 0.1); color: #35ba83"
            class="status"
          >
            运输中
          </div>
          <div
            v-if="row.scope.navication === 2"
            style="background: rgba(254, 170, 79, 0.1); color: #feaa4f"
            class="status"
          >
            签收
          </div>
          <div
            v-if="row.scope.navication === 3"
            style="background: rgba(153, 153, 153, 0.1); color: #999999"
            class="status"
          >
            取消
          </div>
          <div
            v-if="row.scope.navication === 4"
            style="background: rgba(254, 69, 61, 0.1); color: #fe453d"
            class="status"
          >
            退回
          </div>
          <div
            v-if="row.scope.navication === 5"
            style="background: rgba(157, 103, 254, 0.1); color: #9d67fe"
            class="status"
          >
            其他
          </div>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="track-detail" title="查看轨迹详情" @click="handleTrackDetails(row.scope)">轨迹详情</div>
        </template>
      </BaseTable>
    </div>
    <track-detail-drawer :drawer="drawer" :editDta="editDta" @close="close"></track-detail-drawer>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
const BaseFilter = () => import('@/components/filter/BaseFilter')
import TrackDetailDrawer from './components/TrackDetailDrawer.vue'
import { getTrackPages } from '@/api/fee'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'Track',
  components: { BaseTable, BaseMutiInput, BaseFilter, TrackDetailDrawer },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'referenceNumber',
            label: '客户单号',
            width: '170px',
            fixed: 'left',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'trackingNumber',
            label: '追踪单号',
            width: '170px',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'masterTrackingNumber',
            label: '主单号',
            width: '170px',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'uploadOn',
            label: '制单日期',
            width: '160px',
            formatter: (row) =>
              row.uploadOn ? row.uploadOn.slice(0, row.uploadOn.length - 3) : ''
          },
          {
            filed: 'mblNo',
            label: '提单号',
            width: '150px',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'outWarehouseOn',
            label: '出仓日期',
            width: '160px',
            formatter: (row) =>
              row.outWarehouseOn ? row.outWarehouseOn.slice(0, row.outWarehouseOn.length - 3) : ''
          },
          {
            filed: 'navication',
            label: '最新状态',
            width: '150px',
            isCustom: true
          },
          {
            filed: 'tdStatusDescription',
            label: '最新轨迹',
            width: '',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '180px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      searchList: [],
      orderList: [],
      search: '',
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      isActive: false,
      filterList: {
        upDate: [],
        outDate: [],
        companyIds: [],
        tdStatus: []
      },
      drawer: false,
      editDta: {},
      orderId: ''
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    // 轨迹查询
    searchRefNo() {
      if (this.search) {
        this.getList(this.pagerConfig)
      } else {
        this.$message.error('请输入客户单号、追踪单号、提单号')
      }
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            search: this.search,
            ...this.formatterFilter()
          }
        }
        const res = await getTrackPages(params)
        this.loading = false
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 确定
    handleConfirm() {
      if (this.searchList.length > 0) {
        delete this.$refs.baseMutiInput.$data.valueArr[this.$refs.baseMutiInput.$data.valueArr.length - 1]
        this.orderList = this.$refs.baseMutiInput.$data.valueArr
        this.search = this.$refs.baseMutiInput.$data.valueArr.join(';')
        this.$refs.popover.doClose()
      } else {
        this.$message.error('请输入客户单号、追踪单号、提单号')
      }
    },
    // 取消
    handleCancel() {
      this.search = ''
      this.$refs.baseMutiInput.$data.valueArr = ['']
      this.searchList = []
      this.orderList = []
      this.$refs.popover.doClose()
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    // 分页操作
    handlePageChange(obj) {
      this.pagerConfig.pageIndex = obj.pageIndex
      this.searchRefNo()
    },
    formatterFilter() {
      return {
        companyIds:
          this.filterList.companyIds.length !== 0
            ? this.filterList.companyIds.join(',')
            : undefined,
        tdStatus:
          this.filterList.tdStatus.length !== 0
            ? this.filterList.tdStatus.join(',')
            : undefined,
        uploadStart:
          this.filterList.upDate.length !== 0 ? this.filterList.upDate[0] : undefined,
        uploadEnd:
          this.filterList.upDate.length !== 0 ? this.filterList.upDate[1] : undefined,
        outWarehouseStart:
          this.filterList.outDate.length !== 0 ? this.filterList.outDate[0] : undefined,
        outWarehouseEnd:
          this.filterList.outDate.length !== 0 ? this.filterList.outDate[1] : undefined
      }
    },
    // 筛选
    sendFilter() {
      if (
        this.filterList.companyIds.length !== 0 ||
        this.filterList.tdStatus.length !== 0 ||
        this.filterList.upDate.length !== 0 ||
        this.filterList.outDate.length !== 0
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = params
      this.getList(params)
    },
    // 重置
    reset() {
      this.filterList = {
        companyIds: [],
        upDate: [],
        outDate: [],
        tdStatus: []
      }
      this.isActive = false
    },
    // 搜索栏删除订单事件
    handleRemoveTag(val) {
      this.$refs.baseMutiInput.$data.valueArr.forEach((item, index) => {
        if (item === val) {
          this.$refs.baseMutiInput.$data.valueArr.splice(index, 1)
        }
      })
    },
    // 查看轨迹详情
    handleTrackDetails(row) {
      this.editDta = row
      // this.orderId = row.orderId
      this.drawer = true
    },
    close() {
      this.drawer = false
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  &:after {
    clear: both;
  }
  .order-content {
    padding: 8px 8px 0;
    box-sizing: border-box;
    /deep/ .el-input {
      width: 100% !important;
    }
  }
  .opt {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .filter {
      position: absolute;
      right: 0px;
      bottom: 10px;
    }
    .search {
      /deep/ .el-select__tags {
        flex-wrap: nowrap;
        overflow: hidden;
      }
      /deep/ .el-select__tags-text{
        display:inline-block;
        max-width: 130px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
      }
    }
    .searchPop {
      /deep/ .el-popover {
        top: 40px;
        z-index: 9999;
      }
    }
    .pop-btn {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 30px;
      .pop-qx {
        width: 100px;
        height: 40px;
        background: #EEEEEE;
        border-radius: 4px 4px 4px 4px;
        border: 0;
        color: #999999;
        margin-right: 15px;
        cursor: pointer;
      }
      .pop-qd {
        width: 100px;
        height: 40px;
        background: #2395F3;
        border-radius: 4px 4px 4px 4px;
        border: 0;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 120px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .track {
      text-align: left;
      .track-title {
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
        font-weight: bold;
      }
      .track-info {
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
      }
    }
    .status {
      width: 60px;
      height: 26px;
      border-radius: 4px;
      margin: 0 auto;
      line-height: 26px;
    }
    .track-detail {
      color: #2395F3;
      cursor: pointer;
    }
  }
}
</style>
