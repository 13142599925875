<template>
  <Drawer :drawer="drawer" title="轨迹详情" :size="520" @close="handleClose">
    <div class="log-body">
      <div class="track-num">
        追踪单号：<span class="number">{{ orderData.trackingNumber }}</span>
      </div>
      <div class="track-other">
        <div class="country">
          <span>{{ orderData.originCountry }}</span>
          <img src="@/assets/images/order/guide.png" alt="">
          <span>{{ orderData.destinationCountry }}</span>
        </div>
        <div class="navication">
          <template v-if="orderData.navication <= 2">
            <div class="nav-step-text">
              <span style="margin-right: 40px;">已创建</span>
              <span style="margin-right: 40px;">运输中</span>
              <span>已签收</span>
            </div>
            <div class="nav-step-jd">
              <div class="nav-setp-dot nav-dot-blue"></div>
              <div :class="['nav-setp-line', orderData.navication > 0 ? 'nav-line-blue': '']"></div>
              <div :class="['nav-setp-dot', orderData.navication > 0 ? 'nav-dot-blue': '']"></div>
              <div :class="['nav-setp-line', orderData.navication > 1 ? 'nav-line-blue': '']"></div>
              <div v-if="orderData.navication !== 2" class="nav-setp-dot"></div>
              <img v-if="orderData.navication === 2" src="@/assets/images/order/over.png" alt="">
            </div>
          </template>
          <template v-if="orderData.navication >= 3">
            <div class="nav-step-text">
              <span style="margin-right: 40px;">已创建</span>
              <span v-if="orderData.navication === 3">取消</span>
              <span v-if="orderData.navication === 4">退回</span>
              <span v-if="orderData.navication === 5">其他</span>
            </div>
            <div class="nav-step-jd">
              <div class="nav-setp-dot nav-dot-blue"></div>
              <div :class="['nav-setp-line', orderData.navication > 0 ? 'nav-line-blue': '']"></div>
              <div :class="['nav-setp-dot', orderData.navication > 0 ? 'nav-dot-red': '']"></div>
            </div>
          </template>
        </div>
      </div>
      <div class="track-step">
        <div v-for="(item, index) in orderData.trackInfo" :key="index" class="step-item">
          <div :class="['step-title', index === 0 ? 'blue' : '']">
            <div class="dot">
              <div :class="['step-dot', index === 0 ? 'blue-dot' : '']"></div>
            </div>
            <div class="step-time">{{ item.time }}&nbsp;&nbsp;{{ item.location }}</div>
          </div>
          <div :class="['step-info', index === 0 ? 'blue' : '']">
            {{ item.description }}
          </div>
          <div v-if="index !== orderData.trackInfo.length - 1" :class="['step-line', , index === 0 ? 'blue-line' : '']"></div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import { trackEvents } from '@/api/basic'
import config from '@/utils/config'
export default {
  components: {
    Drawer
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    editDta: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      config,
      orderData: {}
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getLists()
      }
    }
  },
  methods: {
    getLists() {
      this.loading = true
      trackEvents({
        supplier: this.config.supplyCode,
        scope: 0,
        orderId: this.editDta.orderId
      }).then((res) => {
        if (res.success) {
          this.orderData = {
            ...res.data[0],
            originCountryCode: this.editDta.originCountryCode,
            countryCode: this.editDta.countryCode,
            navication: res.data[0].lastNavication,
            trackInfo: res.data[0].events && res.data[0].events.map(a => {
              a.localTime = /\d{4}-\d{1,2}-\d{1,2}[ ]\d{1,2}:\d{1,2}/g.exec(a.localTime)[0]
              return a
            })
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.log-body {
  width: 100%;
  height: calc(100vh - 120px);
  margin: 20px auto 0;
  padding: 0 20px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .track-num {
    width: 480px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 6px 6px 6px 6px;
    font-size: 16px;
    font-weight: 400;
    .number {
      color: #2395F3;
    }
  }
  .track-other {
    display: flex;
    width: 480px;
    height: 80px;
    margin-top: 10px;
    .country {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      margin-right: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #2395F3;
      img {
        margin: 0 24px;
      }
    }
    .navication {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 290px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      .nav-step-text {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .nav-step-jd {
        display: flex;
        align-items: center;
        margin-top: 8px;
        .nav-setp-dot {
          width: 8px;
          height: 8px;
          background: #CDCDCD;
          border-radius: 50px;
          opacity: 1;
        }
        .nav-dot-blue {
          background: #2395F3;
        }
        .nav-dot-red {
          background: red;
        }
        .nav-setp-line {
          width: 42px;
          border-bottom: 1px solid #CDCDCD;
          margin: 0 16px;
        }
        .nav-line-blue {
          border-bottom: 1px solid #2395F3;
        }
      }
    }
  }
  .track-step {
    width: 480px;
    height: auto;
    min-height: 438px;
    background: #FBFBFB;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-top: 20px;
    padding: 22px 0 0 20px;
    box-sizing: border-box;
    .step-item {
      position: relative;
      height: auto;
      color: #999999;
      .step-title {
        display: flex;
        align-items: center;
        position: relative;
        height: 16px;
        .dot {
          display: flex;
          justify-content: center;
          width: 12px;
        }
        .step-dot {
          width: 8px;
          height: 8px;
          background: #CDCDCD;
          border-radius: 50px;
        }
        .blue-dot {
          width: 12px;
          height: 12px;
          background: #2395F3;
        }
        .step-time {
          position: absolute;
          left: 26px;
          width: calc(100% - 55px);
          font-size: 12px;
          font-weight: 400;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap;
        }
      }
      .step-info {
        font-size: 14px;
        font-weight: bold;
        color: #999999;
        line-height: 19px;
        margin: 8px 0 0 26px;
        padding-bottom: 24px;
        box-sizing: border-box;
      }
      .step-line {
        position: absolute;
        top: 10px;
        left: 6px;
        height: 100%;
        border-left: 1px solid #CDCDCD;
      }
      .blue {
        color: #2395F3;
      }
      .blue-line {
        border-left: 1px solid #2395F3;
      }
    }
  }
}
</style>
