var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "opt" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c(
              "el-popover",
              {
                ref: "popover",
                staticClass: "searchPop",
                attrs: { placement: "top", trigger: "click", width: "474" },
              },
              [
                _c(
                  "div",
                  { staticClass: "order-content" },
                  [
                    _c("base-muti-input", {
                      ref: "baseMutiInput",
                      model: {
                        value: _vm.searchList,
                        callback: function ($$v) {
                          _vm.searchList = $$v
                        },
                        expression: "searchList",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "pop-btn" }, [
                  _c(
                    "button",
                    { staticClass: "pop-qx", on: { click: _vm.handleCancel } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "button",
                    { staticClass: "pop-qd", on: { click: _vm.handleConfirm } },
                    [_vm._v("确定")]
                  ),
                ]),
              ]
            ),
            _c("el-select", {
              staticStyle: { width: "500px" },
              attrs: {
                multiple: true,
                filterable: "",
                "allow-create": "",
                placeholder: "请输入客户单号、追踪单号、提单号",
              },
              on: { focus: _vm.handleFocus, "remove-tag": _vm.handleRemoveTag },
              model: {
                value: _vm.orderList,
                callback: function ($$v) {
                  _vm.orderList = $$v
                },
                expression: "orderList",
              },
            }),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px", height: "40px" },
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.searchRefNo },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter" },
          [
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 466 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: { model: _vm.filterList, "label-width": "70px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "制单日期" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "320px" },
                          attrs: {
                            type: "daterange",
                            "range-separator": "至",
                            align: "center",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "unlink-panels": "",
                            "picker-options": _vm.pickerOptions,
                          },
                          model: {
                            value: _vm.filterList.upDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterList, "upDate", $$v)
                            },
                            expression: "filterList.upDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "出仓日期" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "320px" },
                          attrs: {
                            type: "daterange",
                            "range-separator": "至",
                            align: "center",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "unlink-panels": "",
                            "picker-options": _vm.pickerOptions,
                          },
                          model: {
                            value: _vm.filterList.outDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterList, "outDate", $$v)
                            },
                            expression: "filterList.outDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "320px" },
                            attrs: {
                              multiple: true,
                              filterable: "",
                              "allow-create": "",
                              placeholder: "请选择客户",
                            },
                            model: {
                              value: _vm.filterList.companyIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "companyIds", $$v)
                              },
                              expression: "filterList.companyIds",
                            },
                          },
                          _vm._l(_vm.companyOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "轨迹状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "320px" },
                            attrs: {
                              multiple: true,
                              filterable: "",
                              "allow-create": "",
                              placeholder: "请选择客户",
                            },
                            model: {
                              value: _vm.filterList.tdStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "tdStatus", $$v)
                              },
                              expression: "filterList.tdStatus",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "已创建", value: "0" },
                            }),
                            _c("el-option", {
                              attrs: { label: "运输中", value: "1" },
                            }),
                            _c("el-option", {
                              attrs: { label: "签收", value: "2" },
                            }),
                            _c("el-option", {
                              attrs: { label: "取消", value: "3" },
                            }),
                            _c("el-option", {
                              attrs: { label: "退回", value: "4" },
                            }),
                            _c("el-option", {
                              attrs: { label: "其他", value: "5" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  tableData: _vm.tableData,
                  loading: _vm.loading,
                },
                on: { handlePageChange: _vm.handlePageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "tdStatusDescription",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "track" }, [
                            _c("div", { staticClass: "track-title" }, [
                              _vm._v(_vm._s(row.scope.tdStatusDescription)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "track-info",
                                staticStyle: { "margin-top": "5px" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(row.scope.tdStatusTime) +
                                    " " +
                                    _vm._s(row.scope.tdStatusLocation) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "navication",
                      fn: function (row) {
                        return [
                          row.scope.navication === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "status",
                                  staticStyle: {
                                    background: "rgba(255, 116, 68, 0.1)",
                                    color: "#ff7444",
                                  },
                                },
                                [_vm._v(" 已创建 ")]
                              )
                            : _vm._e(),
                          row.scope.navication === 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "status",
                                  staticStyle: {
                                    background: "rgba(53, 186, 131, 0.1)",
                                    color: "#35ba83",
                                  },
                                },
                                [_vm._v(" 运输中 ")]
                              )
                            : _vm._e(),
                          row.scope.navication === 2
                            ? _c(
                                "div",
                                {
                                  staticClass: "status",
                                  staticStyle: {
                                    background: "rgba(254, 170, 79, 0.1)",
                                    color: "#feaa4f",
                                  },
                                },
                                [_vm._v(" 签收 ")]
                              )
                            : _vm._e(),
                          row.scope.navication === 3
                            ? _c(
                                "div",
                                {
                                  staticClass: "status",
                                  staticStyle: {
                                    background: "rgba(153, 153, 153, 0.1)",
                                    color: "#999999",
                                  },
                                },
                                [_vm._v(" 取消 ")]
                              )
                            : _vm._e(),
                          row.scope.navication === 4
                            ? _c(
                                "div",
                                {
                                  staticClass: "status",
                                  staticStyle: {
                                    background: "rgba(254, 69, 61, 0.1)",
                                    color: "#fe453d",
                                  },
                                },
                                [_vm._v(" 退回 ")]
                              )
                            : _vm._e(),
                          row.scope.navication === 5
                            ? _c(
                                "div",
                                {
                                  staticClass: "status",
                                  staticStyle: {
                                    background: "rgba(157, 103, 254, 0.1)",
                                    color: "#9d67fe",
                                  },
                                },
                                [_vm._v(" 其他 ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "track-detail",
                              attrs: { title: "查看轨迹详情" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTrackDetails(row.scope)
                                },
                              },
                            },
                            [_vm._v("轨迹详情")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2376155312
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("track-detail-drawer", {
        attrs: { drawer: _vm.drawer, editDta: _vm.editDta },
        on: { close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }